import { CustomButton } from '@/lib/components';
import { PaymentSetting } from '@/lib/types';
import { currencyFormatter } from '@/lib/utils/formatters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, DatePicker, Form, Row } from 'antd';
import dayjs from 'dayjs';
import { NumericFormat } from 'react-number-format';
import { BankAccountSelect } from './bankAccountSelect';
import styles from './styles.module.scss';

type PartialPaymentRowProps = {
  field: any;
  remove: (name: any) => void;
  showRemove: boolean;
  disabled: boolean;
  paymentSetting?: PaymentSetting;
  term?: number;
};

export const PartialPaymentRow = ({
  field,
  remove,
  showRemove,
  disabled,
  paymentSetting,
  term
}: PartialPaymentRowProps) => {
  return (
    <Row gutter={12}>
      <Col span={10}>
        <Form.Item
          name={[field.name, 'bankAccount']}
          rules={[
            {
              validator: async (rule, value) => {
                if (!value || !value.iban) {
                  throw new Error('Verplicht');
                }
              },
            },
          ]}
        >
          <BankAccountSelect
            options={
              paymentSetting?.bankAccounts.filter((x) => x.isValidated) || []
            }
            disabled={disabled}
          />
        </Form.Item>
      </Col>
      <Col span={3}>
        <span className='font-scale'>{term}</span>
      </Col>
      <Col span={5} className='font-scale'>
        <Form.Item
          name={[field.name, 'date']}
          rules={[
            {
              validator: async (rule, value) => {
                if (!value) {
                  throw new Error('Verplicht');
                }
              },
            },
          ]}
        >
          <DatePicker
            format={dayjs.Ls.nl.formats.L}
            picker="date"
            className={styles.dashedDate}
            disabled={disabled}
          />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item
          name={[field.name, 'value']}
          normalize={currencyFormatter().parse}
          rules={[
            {
              type: 'number',
              min: 0.01,
              message: 'Minimaal €0,01',
            },
          ]}
        >
          <NumericFormat
            className={styles.dashedNumber + ' font-scale'}
            step={1}
            decimalSeparator=","
            fixedDecimalScale={true}
            decimalScale={2}
            thousandSeparator="."
            disabled={disabled}
            prefix="€ "
          />
        </Form.Item>
      </Col>
      <Col span={2}>
        {showRemove && (
          <CustomButton
            type="text"
            shape="circle"
            toolTipKey="payment.action.deletePayment"
            onClick={() => remove(field.name)}
            icon={<FontAwesomeIcon color="red" icon="trash-alt" />}
          />
        )}
      </Col>
    </Row>
  );
};