import {
  CustomButton,
  CustomColumnType,
  CustomTable,
  DocumentViewModal,
} from '@/lib/components';
import {
  useBatchDeleteDocuments,
  useDeleteDocument,
  useGetDocuments,
} from '@/lib/queries';
import { OtherDocument, OtherDocumentsFilter, SortOrder } from '@/lib/types';
import { getFilterBase } from '@/lib/utils/dynamic-table-filter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Modal, Popconfirm, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const baseColumnSettings: CustomColumnType<OtherDocument> = {
  enableDefaultSorter: false,
  sorter: () => 0,
  onFilter: (_, __) => true,
  filterMultiple: false,
};

export const Documents = () => {
  const { t } = useTranslation();

  const [total, setTotal] = useState(0);
  const [tableSettings, setTableSettings] = useState<OtherDocumentsFilter>({
    page: 1,
    pageSize: 10,
    orderField: 'uploadDate',
    order: SortOrder.Descending,
    exactMatch: false,
  });

  const [selectedDocuments, setSelectedDocuments] = useState<OtherDocument[]>(
    [],
  );

  const { isLoading, data: documents } = useGetDocuments(tableSettings);

  useEffect(() => {
    setTotal(documents?.total || 0);
    setSelectedDocuments((selectedDocuments) =>
      selectedDocuments.filter(
        (x) => !!documents?.data.find((item) => item.id === x.id),
      ),
    );
  }, [documents]);

  const onTableChange = async (pagination, filters, sorter) => {
    const filter = getFilterBase(
      pagination,
      filters,
      sorter,
      tableSettings.pageSize,
      'uploadDate',
    );
    setTableSettings((current) => ({ ...current, ...filter }));
  };

  const resetTable = () => {
    setTableSettings((current) => ({ ...current, page: 1 }));
  };
  const deleteMutation = useDeleteDocument();
  const deleteDocument = async (id: number) => {
    await deleteMutation.mutateAsync(id);
    resetTable();
  };

  const batchDeleteMutation = useBatchDeleteDocuments();
  const deletedSelected = async () => {
    await batchDeleteMutation.mutateAsync(selectedDocuments.map((x) => x.id));
    resetTable();
  };

  const showDeleteSelectedConfirmModal = () => {
    const overflow = selectedDocuments.length > 10;
    const contentText = overflow
      ? t(`otherDocuments.actions.delete.contentOverflow`, {
          filenames: selectedDocuments
            .slice(0, 10)
            .map((x) => x.documentName)
            .join(', '),
          count: selectedDocuments.length - 10,
        })
      : t(`otherDocuments.actions.delete.content`, {
          filenames: selectedDocuments.map((x) => x.documentName).join(', '),
        });

    Modal.confirm({
      title: t(`otherDocuments.actions.delete.title`),
      content: contentText,
      okText: t(`otherDocuments.actions.delete.ok`),
      cancelText: t('general.actions.cancel'),
      width: '800px',
      onOk: deletedSelected,
      onCancel() {},
    });
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: OtherDocument[]) => {
      setSelectedDocuments(selectedRows);
    },
    getCheckboxProps: (record: OtherDocument) => ({}),
    preserveSelectedRowKeys: false,
  };

  return (
    <>
      <Row justify="end">
        <Col>
          <CustomButton
            toolTipKey="otherDocuments.action.delete"
            disabled={!selectedDocuments.length}
            type="primary"
            danger
            icon={<FontAwesomeIcon icon="trash-alt" />}
            onClick={() => showDeleteSelectedConfirmModal()}
          />
        </Col>
      </Row>
      <CustomTable
        rowSelection={rowSelection}
        rowKey="id"
        dataSource={documents?.data}
        loading={isLoading}
        onChange={onTableChange}
        pagination={{
          current: tableSettings.page,
          pageSize: tableSettings.pageSize,
          hideOnSinglePage: true,
          total: total,
          onChange: () => {},
        }}
        columns={[
          {
            title: t('otherDocuments.name'),
            dataIndex: 'documentName',
            ...baseColumnSettings,
          },
          {
            title: t('otherDocuments.filename'),
            dataIndex: 'originalFileName',
            ...baseColumnSettings,
          },
          {
            title: t('otherDocuments.year'),
            dataIndex: 'year',
            ...baseColumnSettings,
          },
          {
            title: t('otherDocuments.uploadDate'),
            dataIndex: 'uploadDate',
            defaultRender: 'dateonly',
            ...baseColumnSettings,
            defaultSearch: 'dateonly',
          },

          {
            width: 100,
            align: 'right',
            dataIndex: 'id',
            render: (id, record) => (
              <>
                <DocumentViewModal id={id} idType="document" />
                <Popconfirm
                  title={t('otherDocuments.actions.delete.single', {
                    filename: record.documentName,
                  })}
                  onConfirm={() => deleteDocument(id)}
                >
                  <CustomButton
                    type="link"
                    toolTipKey="otherDocuments.actions.delete"
                    danger
                    shape="circle"
                    size="small"
                    icon={<FontAwesomeIcon icon="trash-alt" />}
                  />
                </Popconfirm>
              </>
            ),
          },
        ]}
      />
    </>
  );
};
