import {
  Accent,
  CmsTooltip,
  CustomButton,
  GenericModal,
} from '@/lib/components';
import { Relation, User } from '@/lib/types/user';
import { showNotification } from '@/lib/utils/showNotification';
import { SwapOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox, Col, Form, Input, List, Row, Space } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RelationSearch } from './relationSearch';

interface AddEditUserProps {
  loading: boolean;
  user?: User;
  onFinish: (user: User, relations: Relation[]) => void;
  onModalClose: () => void;
  roles: string[];
}

export const AddEditUser: FC<AddEditUserProps> = ({
  loading,
  user,
  onModalClose,
  onFinish,
  roles,
}) => {
  const { t } = useTranslation();
  const [primaryRelation, setPrimaryRelation] = useState<Relation | undefined>(
    user?.relation,
  );
  const [selectedRelations, setSelectedRelations] = useState<Relation[]>(
    user?.relations.filter((x) => x.id !== user.relationId) || [],
  );

  const [customerNumber, setCustomerNumber] = useState<string>();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const addRelation = (relation: Relation) => {
    if (
      primaryRelation?.id === relation.id ||
      selectedRelations.find((x) => x.id === relation.id)
    ) {
      showNotification(
        'warning',
        t('user.extra-customer-relations.errors.duplicate'),
      );
      return;
    }
    setSelectedRelations((cv) => [...cv, relation]);
    setIsModalVisible(false);
  };

  const removeRelation = (relation: Relation) => {
    setSelectedRelations((cv) => cv.filter((x) => x.id !== relation.id));
  };

  const onSubmit = (userState: User) => {
    onFinish(userState, selectedRelations);
  };

  useEffect(() => {
    setSelectedRelations((cv) =>
      cv.filter((e) => e.id !== primaryRelation?.id),
    );
  }, [primaryRelation]);

  return (
    <>
      {!primaryRelation && (
        <RelationSearch
          onSelect={setPrimaryRelation}
          customerNumber={customerNumber}
          setCustomerNumber={setCustomerNumber}
        />
      )}

      <Form
        hidden={!primaryRelation}
        labelCol={{ span: 24 }}
        onFinish={(values) =>
          onSubmit({ ...user, ...values, relationId: primaryRelation?.id })
        }
      >
        <Form.Item
          initialValue={user?.email}
          name="email"
          label="E-mailadres"
          rules={[
            {
              required: true,
              message: 'E-mailadres is verplicht',
            },
            {
              type: 'email',
              message: 'Geen geldig e-mailadres',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Space direction="vertical" size="small">
          <Accent>{t('user.relation')}</Accent>
          <Space size="small">
            <Input value={primaryRelation?.name} disabled />
            <CmsTooltip toolTipKey="user.relations.clear">
              <CustomButton
                disabled={!!user?.roles.length}
                onClick={() => setPrimaryRelation(undefined)}
                type="primary"
                size="small"
                ghost
                shape="circle"
                icon={<SwapOutlined />}
              ></CustomButton>
            </CmsTooltip>
          </Space>
        </Space>
        <Row gutter={16}>
          <Col>
            <Accent>{t('user.extra-customer-relations.relations')}</Accent>
          </Col>
        </Row>
        <List
          locale={{ emptyText: ' ' }}
          dataSource={selectedRelations.filter(
            (x) => x.id !== primaryRelation?.id,
          )}
          size="small"
          renderItem={(relation) => (
            <List.Item>
              <Input value={relation?.name} disabled />
              <CmsTooltip toolTipKey="user.relations.delete">
                <CustomButton
                  onClick={() => removeRelation(relation)}
                  type="link"
                  danger
                  disabled={!!user?.roles.length}
                  icon={
                    <FontAwesomeIcon icon={['far', 'trash-alt']} size="sm" />
                  }
                ></CustomButton>
              </CmsTooltip>
            </List.Item>
          )}
        />
        <CustomButton
          onClick={() => setIsModalVisible(true)}
          disabled={!!user?.roles.length}
        >
          {t('user.extra-customer-relations.actions.add-relation')}
        </CustomButton>
        <Form.Item label="Rollen" name="roles" initialValue={user?.roles}>
          <Checkbox.Group options={roles} />
        </Form.Item>
        <Row justify="center">
          <CustomButton loading={loading} type="link" onClick={onModalClose}>
            {t('general.actions.cancel')}
          </CustomButton>
          <CustomButton loading={loading} htmlType="submit" type="primary">
            {t('general.actions.save')}
          </CustomButton>
        </Row>
      </Form>
      <GenericModal
        bodyStyle={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          minHeight: 400,
        }}
        isVisible={isModalVisible}
        hideModal={() => setIsModalVisible(false)}
        footer={false}
      >
        <RelationSearch
          locationId={user?.relation.locationId}
          onSelect={addRelation}
          setCustomerNumber={setCustomerNumber}
          customerNumber={customerNumber}
        />
      </GenericModal>
    </>
  );
};
