import { Accent, DetailItem } from '@/lib/components';
import { useRelationContext } from '@/lib/context';
import { IdentifierCategory } from '@/lib/types';
import { Divider, List, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

export const Company = () => {
  const { relation } = useRelationContext();
  const { t } = useTranslation('translation', {
    keyPrefix: 'company.section',
  });
  return (
    <>
      <Space direction="vertical" style={{ width: '100%' }} size="small">
        <Accent type="h3" color="primary">
          {t('details.title')}
        </Accent>
        <DetailItem name={t('details.name')} value={relation?.name} />
        <DetailItem name={t('details.zipcode')} value={relation?.zipcode} />
        <DetailItem name={t('details.address')} value={`${relation?.street || ''} ${relation?.houseNumber || ''}`}
        />
        <DetailItem name={t('details.city')} value={relation?.city} />
        <DetailItem name={t('details.country')} value={relation?.country} />
        <DetailItem name={t('details.kvkNumber')} value={relation?.identifiers.find((identifier) => identifier.category === IdentifierCategory.COC)?.identifier} />
        <DetailItem name={t('details.alfaClientNumber')} value={relation?.identifiers.find((identifier) => identifier.category === IdentifierCategory.CUSTOMER)?.identifier} />
        <Divider />

        <Accent type="h3" color="primary">
          {t('mailbox.title')}
        </Accent>
        <Typography.Text>
          {t('mailbox.prefix')}
        </Typography.Text>
        <DetailItem name={t('mailbox.buyInvoice')} value={relation?.mailbox} />
        <DetailItem name={t('mailbox.sellInvoice')} value={relation?.mailbox?.split('@')[0] + "+verkoop@" + relation?.mailbox?.split('@')[1]} />
        <Divider />

        <Accent type="h3" color="primary">
        {t('senders.title')}
        </Accent>
        <Typography.Text>
          {t('senders.prefix')}
        </Typography.Text>
        <ul>
          {relation?.safeEmailAddresses?.map((x) => (
            <li key={x}>{x}</li>
          ))}
        </ul>
        <Divider />
        
        <Typography.Paragraph>
          Op deze pagina worden de ingestelde bedrijfsgegevens getoond, als deze gewijzigd moeten worden, neem dan contact op met je Alfa contactpersoon.
        </Typography.Paragraph>
        
      </Space>
    </>
  );
};
