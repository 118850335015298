import { Dayjs } from 'dayjs';
import { Invoice } from '../types';

export interface Payment {
  id: number;
  iban: string;
  isBatchSelected: boolean;
  amount: number;
  status: PaymentProcessStatus;
  blobStorageUri?: string;
}

export interface PaymentFilter {
  processStatus: PaymentProcessStatus;
}

export enum PaymentProcessStatus {
  None,
  InProgress,
  Error,
  Success,
}

export interface PaymentInvoiceItem {
  invoice: Invoice;
  nettingInvoices: Invoice[];
  isExcluded: boolean;
  paymentSetting?: PaymentSetting;
  receiverName?: string;
  paymentTerm?: number;
  discount?: number;
  netInvoiceAmount: number;
  partialPayments: PartialPayment[];
}

export interface PaymentInfo {
  bankAccount?: BankAccountBase;
  paymentTerm: number;
  discount?: number;
  date: Dayjs;
}

interface PartialPayment {
  value: number;
  bankAccount?: BankAccountBase;
  date: Dayjs;
}

export interface PaymentSetting {
  id: number;
  ownerRelationId: string;
  counterRelationId: string;
  paymentTerm?: number;
  defaultDiscount?: number;
  name?: string;
  street: string;
  houseNumber: string;
  city?: string;
  zipcode?: string;
  country?: string;
  bankAccounts: PaymentSettingBankAccount[];
}

export interface PaymentSettingModifyRequest {
  paymentTerm?: number;
  defaultDiscount?: number;
  bankAccounts: PaymentSettingBankAccount[];
}

export interface PaymentSettingV1 {
  id: number;
  name?: string;
  mailbox: string;
  street: string;
  houseNumber: string;
  city?: string;
  zipcode?: string;
  country?: string;
  isValidated: boolean;
  ownerRelationId: string;
  counterRelationId: string;
  iban?: string;
  bic?: string;
  paymentTerm?: number;
  defaultDiscount?: number;
}

export interface PaymentSettingBankAccount extends BankAccountBase {
  id: number;
  name: string;
  isValidated: boolean;
}

export interface BankAccountBase {
  iban: string;
  bic?: string;
}
